.review-form-container {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    margin: auto;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.review-form {
    display: flex;
    flex-direction: column;
}

.form-group {
    margin-bottom: 20px;
}

.form-group label {
    font-weight: bold;
    margin-bottom: 10px;
}

.star-rating {
    display: flex;
    justify-content: flex-start;
    color: gold;
}

.star-rating span {
    font-size: 50px;
    cursor: pointer;
    color: gold;
}

.button-group {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: center;
}

.button-active, .button-inactive {
    padding: 5px 10px;
    border-radius: 20px;
    border: 3px solid #ddd;
    background-color: transparent;
    cursor: pointer;
    transition: all 0.3s ease;
}

.button-active {
    color: white;
    background-color: #444;
}

.button-inactive {
    color: #444;
}

.dropzone {
    border: 2px dashed #ddd;
    padding: 10px;
    text-align: center;
    cursor: pointer;
}

textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    resize: vertical;
}

.submit-button {
    background-color: #444;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.submit-button:hover {
    background-color: #555;
}

.thumbnail {
    width: 100px;
    height: 100px;
    object-fit: cover;
    margin: 5px;
    cursor: pointer;
}

.image-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0,0,0,0.3);
    z-index: 1000;
}

.image-modal img {
    width: 80%;
    height: auto;
}