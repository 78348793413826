.greens-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    color: #333;
    font-family: 'Arial', sans-serif;
    padding: 20px;
    width: 100vw;
    margin: 0 auto;
    box-sizing: border-box;
}

.map-container {
    width: 100%;
    height: 50vh;
    margin-bottom: 20px;
}

.table {
    width: 70%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.table th, .table td {
    text-align: left;
    padding: 8px;
    border-bottom: 1px solid #ddd;
}

.table th {
    background-color: #f2f2f2;
}

.table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.table tr:hover {
    background-color: #f1f1f1;
}

@media screen and (min-width: 768px) {
    .map-container, .table {
        width: 70vw;
    }
}
