.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.hamburger-menu {
  display: none; 
  cursor: pointer;
}

.hamburger-menu .bar {
  width: 25px;
  height: 3px;
  background-color: #fffdfd;
  margin: 5px 0;
  transition: transform 0.4s, opacity 0.4s;
}


.hamburger-menu.open .bar:nth-child(1) {
  transform: rotate(-45deg) translate(-5px, 5px);
}
.hamburger-menu.open .bar:nth-child(2) {
  opacity: 0;
}
.hamburger-menu.open .bar:nth-child(3) {
  transform: rotate(45deg) translate(-5px, -5px);
}

.nav-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  color: white;
  background-color: #e9e9e9;
  background-image: linear-gradient(to top, #0f9b0f 0%, #74b816 100%);
  box-shadow: 0 4px 6px rgb(172 172 172 / 90%);
  position:relative;
}

.nav-home,
.nav-links {
  display: flex;
  align-items: center;
  color: white;
}

.nav-item,
.nav-home a {
  color: #ffffff;
  text-decoration: none;
  margin: 0 10px;
  padding: 5px 10px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.nav-item:hover,
.nav-home a:hover {
  background-color: #00680e;
  color:#f8f8f8;
}

.nav-username {
  margin-right: 20px;
  color:#f5f4f4
}

@media (min-width:828px) {
  .nav-username {
    display: none;
   }
}
@media (max-width: 828px) {
  .nav-bar {
      flex-direction: row; 
      justify-content: space-between; 
      align-items: center; 
  }
 
  .nav-links {
      display: none;
      flex-direction: column;
      position: absolute;
      top: 60px; 
      right: 20px;
      background-color: #1e1e1e;
      padding: 10px;
      border-radius: 5px;
      z-index: 10000;
  }

  .nav-links.open {
      display: flex;
  }

  .hamburger-menu {
      display: block;
  }
}

.appBody{
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-height: 100vh;
  justify-content: space-between;
}