.architects-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    gap: 10px;
    width: 90%;
    margin: 0 auto; 
  }
  
  @media (max-width: 768px) {
    .architects-container {
      padding: 10px;
      width: 100%;
    }
  
    .leaflet-container {
      height: 300px; 
      width: 90%; 
      margin: 0 auto;
    }
  }
  
  .material-react-table .MuiTableContainer-root {
    overflow-x: auto; 
  }
  
  a {
    color: #1976d2;
    text-decoration: underline;
    cursor: pointer; 
  }
  
  a:hover {
    text-decoration: none; 
  }
  
  .leaflet-popup-content {
    font-size: 14px; 
  }

  .leaflet-container {
    height: 400px;
    width: 90%; 
    margin: 0 auto; 
  }