.user-courses-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    color: #333;
    font-family: 'Arial', sans-serif;
    padding: 20px;
    width: 100vw; 
    margin: 0 auto; 
    box-sizing: border-box;
  }

  .map2-container {
    width: 100%; 
    height: 100%;
    margin-bottom: 20px;
  }

  .leaflet-map {
    width: 70vw;
    height: 40vh;
    resize: both;
    display:flex;
    position: relative;
  }
  

.social-share-buttons {
    display: flex;
    justify-content: center;
    padding: 1rem;
    width: 100%;
}

@media screen and (min-width: 768px) {
    .map2-container, .table {
        width: 70vw; 
    }
}

.base-Tabs-root, .base-Tabs-horizontal {
    width: 100%;
}