
.course-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    color: #333;
    font-family: 'Arial', sans-serif;
}
.course-details-table {
  width: 100%;
  max-width: 100%; 
  overflow-y: auto;
  border-collapse: collapse;
  line-height: 18px;
  font-size: 0.9em;
}

.course-details-table th, .course-details-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

@media (max-width: 768px) {
  .course-details-table {
    font-size: 0.8em;
  }
}
.course-title {
    color: rgb(0, 0, 0);
}

.course-address {
    color: rgb(141, 141, 141);
}

.course-details {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 61%;
    padding: 10px;
}

.property-card {
    background-color: #f0f0f0;
    padding: 15px;
    margin: 10px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 200px;
    text-align: center;
    font-family: unset;
}

.loading {
    text-align: center;
    font-size: 20px;
}

.map-container1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90vw;
    height: 60vh;
}

.leaflet-map {

  width: 100%;
  height: 100%;
  resize: both;

}
.leaflet-container {
  position: center;
  height: 800px;
  width: 100%;
}

.leaflet-control-measure {
  background-color: white;
}

@media (max-width: 768px) {
  .map-container1 {
      font-size: 0.8em;
  }
}

  .reviews-section {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 70vw;
    height: 60vh;
    padding: 20px;
    margin: 20px;
  }
  
  .review {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    gap: 12px;
    align-items: flex-start;
    border-bottom: 1px solid #ddd;
    padding-bottom: 15px;
    margin-bottom: 15px;
  }
  
  .review:last-child {
    border-bottom: none;
  }
  
  .review strong {
    color: #333;
    font-size: 1.1em;
  }
  
  .review p {
    color: #666;
    margin: 5px 0;
  }
  
.user-images {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
}

.user-images img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 4px;
}
  
  .review-date {
    font-style: italic;
    color: #999;
  }

.upvote-button, .downvote-button {
    background: transparent;
    color: black;
    font-size: medium;
    cursor: pointer;
    font-family: system-ui;
    font-weight: 600;
}

.review-stars {
  margin: auto;
  font-size: xx-large;
}

.review-date {
  margin: auto;
  font-size: medium;
}
.review-user {
  margin: auto;
  font-size: medium;
}

.image-gallery .image-gallery-slide .image-gallery-image {
    width: 50% !important;
    height: 50% !important;
    object-fit: contain !important;
  }


  .course-header {
    display: flex;
    align-items: center;
}

.course-title {
    margin-right: 10px;
}

.icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
    position: relative;
}

.icon::after {
    content: attr(title);
    white-space: nowrap;
    display: none;
    position: absolute;
    left: 100%;
    margin-left: 8px;
    background-color: #f0f0f0; 
    padding: 2px 6px;
    border-radius: 4px; 
    font-size: 0.875rem; 
    z-index: 1;
}

.icon:hover::after {
    display: block;
}

.Course-TabsList {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;

}

.base-Tabs-root, .base-Tabs-horizontal {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;

}