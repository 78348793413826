.container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  margin-bottom: 20px;
}

.search-boxes {
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.search-box {
  flex-basis: calc(5% - 10px);
  min-width: 200px;
}

.map-container {
  width: 85%;
  height: 400px;
  margin-bottom: 20px;
}

.comparison-table {
  width: 75vw;
  max-width: 100%; 
  margin: 0 auto; 
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.comparison-column {
  border: 1px solid #ccc;
  padding: 10px;
  margin: 5px;
}

.comparison-item {
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
}

.better {
  font-weight: bold;
}

@media (max-width: 600px) {
  .search-box {
    flex-basis: 100%;
  }
}