img{
    border: 5px;
    border-radius: 4px;
}

.footerMain {
  background-image: linear-gradient(to top, #0f9b0f 0%, #74b816 100%);
  padding: 20px;
  color: white;
  text-align: center;
}
.footerTop{
    display: flex;
    justify-content: space-around;
}
.footerBottom{
    padding-top: 50px;
}

.footerLeft{
    display: flex;
    flex-direction: column;
    gap: 5px;
    a {
        color: white;
    }
    /* This tells the left item to take up only as much space as it needs */
    flex: 0.5 1 auto; /* Flex-grow, flex-shrink, flex-basis */
}

.footerRight{
     /* This tells the right item to take up all remaining space */
     flex: 1 1 auto; /* Flex-grow, flex-shrink, flex-basis */
}
.separator {
    width: 1px;
    background: white;
}