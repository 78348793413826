body {
  justify-content: center;
}

form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

input[type="text"] {
  flex: 1 1 200px;
  margin: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.mapboxgl-popup-close-button {
  background-color: #007cbf;
  color: black;
  width: 0px; 
  height: 20px; 
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px; 
  color: white;
}
button {
  padding: 10px 20px;
  margin: 10px;
  background-color: #007cbf;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.course-detail-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}


.search-form {
  order: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px;
  margin-bottom: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.search-form input[type="text"] {
  flex: 1 1 200px;
  margin: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.search-button {
  z-index: 1000 !important;
  padding: 10px 20px;
  background-color: #007cbf;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.course-list.show {
  display: flex;
  left: 2.5%;
  top: 5%;
  flex-direction: column;
}

.course-list {
  display: none; 
  position: absolute;
  top: 1%;
  left: 0px; 
  width: 40%; 
  height: 85%; 
  resize: both;
  overflow: auto;
  background-color: #f9f9f9;
  z-index: 2; 
  overflow-y: auto;
}

.course-properties-new {
  display: block;
  position: absolute;
  left: 22.5%;
  top: 1%;
  width: 20%;
  height: 50%;
  background-color: rgba(249, 249, 249, 0.8);
  z-index: 2;
  overflow-y: auto;
  border-radius: 10px; 
}

.course-item.expanded {
  background-color: #f0f0f0;
  border-color: #007bff;
}

.course-details {
  padding: 10px;
  border-top: 1px solid #ccc;
  margin-top: 10px;
}

.course-list-toggle-icon.detached {
  display: none;
}
.course-list-toggle-icon {
  position: absolute; 
  padding: 5px;
  left: 1%;
  top: 1.25%;
  width: 5%;
  height: 2.5%;
  background: transparent;
  background-image: url('../assets/images/arrow-icon.png');
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
}

.course-list-toggle-icon.on {
  transform: rotate(180deg);
}
.map-and-course-list-container {
  order: 2;
  position: relative; 
  display: flex; 
  width: 90vw; 
  height: 80vh; 
  align-items: stretch; 
}
.map-container {
  display: flex !important;
  position: 2 !important;
  flex-grow: 1 !important;
  height: 100% !important; 
}

.course-item {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  transition: background-color 0.3s ease;
}

.course-item:hover {
  background-color: #f0f0f0;
  cursor: pointer;
}

.course-info {
  flex-grow: 1;
}

.course-name {
  margin: 0;
  font-size: 16px;
  color: #333;
}

.course-location {
  margin: 5px 0;
  font-size: 14px;
  color: #666;
}

.course-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.course-action-button {
  margin-left: 10px;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

.course-action-button:hover {
  background-color: #0056b3;
}

.icon {
  margin-right: 5px;
}
.menu-icon {
  width: 30px;
  height: 3px;
  background-color: #333;
  margin: 6px 0;
}

.search-panel {
  display: flex;
  position: absolute;
  top: 20px;
  left: 100px;
  z-index: 998;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 300px;
}

.property-columns {
  display: flex;
  justify-content: space-between;
}

.property-column {
  flex: 1;
  margin-right: 10px;
}

.mapboxgl-popup-close-button{
  background-color: transparent;
  color:#120000; 
  position: top;
}

.mapboxgl-popup-close-button:hover{
  background-color: rgba(0, 0, 0, 0.226);
  color:#120000; 
}

.mapboxgl-popup-content {
  width: 20vh;
  color:rgb(73, 41, 41);
  background-color:rgba(255, 253, 253, 0.83);
}

.mapbox-popup {
  background-color: transparent;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}

.course-properties {
  order: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  text-align: center;
}

.course-details {
  display: grid;
  grid-template-columns: auto auto; 
  gap: 10px;
  margin-top: 10px;
}

.course-details p {
  margin: 0;
  padding: 5px;
  border: 1px solid #ddd;
  box-sizing: border-box;
}

.course-details p:nth-child(odd) {
  background-color: #f9f9f9;
}

.course-details p:nth-child(even) {
  background-color: #fff;
}

.course-properties table {
  border-collapse: collapse;
  width: 30%;
  align-self: center;
}

.course-properties th, .course-properties td {
  border: 1px solid #ddd;
  padding: 8px;
}

.course-properties tr:nth-child(even) {
  background-color: #f2f2f2;
}

.course-properties th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #007cbf;
  color: white;
}
  .search-form {
    display: flex;
    order: 1;
    justify-content: center;
    padding: 20px;
    margin-bottom: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: calc(100vw - 40px)
  }

  .react-autosuggest__container {
    position: relative;
  }
  
  .react-autosuggest__input {
    width: 240px;
    height: 30px;
    padding: 10px 20px;
    font-weight: 300;
    font-size: 16px;
    border: 1px solid #aaa;
    border-radius: 4px;
  }
  
  
  .react-autosuggest__container--open .react-autosuggest__input {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  .react-autosuggest__container--open .react-autosuggest__suggestions-container {
    display: block;
    position: absolute;
    top: 51px;
    width: 280px;
    border: 1px solid #aaa;
    background-color: #fff;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
  }
  
  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  
  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
  }
  
  .react-autosuggest__suggestion:not(:first-child) {
    border-top: 1px solid #ddd;
  }
  
  .react-autosuggest__suggestion--focused {
    background-color: #0C7EAF;
    color: #fff;
  }
  
  .suggestion-content {
    display: flex;
    align-items: center;
    background-repeat: no-repeat;
  }
  
  .dancounsell {
    background-image: url(https://s3.amazonaws.com/uifaces/faces/twitter/dancounsell/48.jpg);
  }
  
  .ladylexy {
    background-image: url(https://s3.amazonaws.com/uifaces/faces/twitter/ladylexy/48.jpg);
  }
  
  .mtnmissy {
    background-image: url(https://s3.amazonaws.com/uifaces/faces/twitter/mtnmissy/48.jpg);
  }
  
  .steveodom {
    background-image: url(https://s3.amazonaws.com/uifaces/faces/twitter/steveodom/48.jpg);
  }
  
  .name {
    margin-left: 68px;
    line-height: 45px;
  }
  
  .highlight {
    color: #ee0000;
    font-weight: bold;
  }
  
  .react-autosuggest__suggestion--focused .highlight {
    color: #120000;
  }

  @media (max-width: 600px) {
    .course-details {
      grid-template-columns: auto;
    }
  }